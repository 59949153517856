
import G2ColsImgCopy from './goose/g-2-cols-img-copy';
import richtext from '~/utils/richtext';
import parseLinkHref from '~/utils/parseLinkHref';
import cmsComponent from 'mixins/cmsComponent';

export default {
  name: 'cms-2-cols-img-copy',
  components: {
    G2ColsImgCopy,
  },
  mixins: [cmsComponent],
  computed: {
    paragraph() {
      if (typeof this.blok.paragraph !== 'undefined') {
        return richtext(this.blok.paragraph, false, true);
      }
      return undefined;
    },
    parseLink() {
      if (typeof this.blok.link_url !== 'undefined') {
        return parseLinkHref(this.blok.link_url, true, { linkName: 'linkUrl' });
      }
      return undefined;
    },
    richtextHeadline() {
      return this.blok.richtext_headline
        ? richtext(this.blok.richtext_headline)
        : null;
    },
  },
};
